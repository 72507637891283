.modal-thanks {
  position: relative;
  z-index: 1;
  max-width: 566px;
  width: 100%;
  min-height: 275px;
  background: $c-white;
  box-shadow: 0px 91px 36px rgba(0, 0, 0, 0.01), 0px 51px 31px rgba(0, 0, 0, 0.02), 0px 23px 23px rgba(0, 0, 0, 0.03), 0px 6px 13px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include xs {
    padding: 30px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    border-radius: 50%;
    background: linear-gradient(180deg, #263348 48.44%, #15B06E 100%);
    margin: 0 0 20px;
  }

  &__text {
    font-weight: 600;
    font-size: 25px;
    color: $c-blue;

    @include xs {
      font-size: 20px;
    }
  }

  &__dots {
    position: absolute;
    top: 50px;
    left: 75px;
    z-index: -1;
  }
}
