.home-partners {
  padding: 0 0 50px;

  @include md {
    padding: 0 0 40px;
  }

  @include xs {
    padding: 0 0 50px;
  }

  &__title {
    margin: 0 0 18px;

    @include md {
      margin: 0 0 15px;
    }

    @include xs {
      margin: 0 0 10px;
    }
  }

  &__subtitle {
    font-weight: 600;
    font-size: 25px;
    color: $c-blue;
    text-align: center;
    margin: 0 0 15px;

    @include xs {
      margin: 0;
      font-size: 16px;
    }
  }

  &__slider {}

  &__item {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    height: 125px;

    img {
      height: 100%;
      object-fit: cover;
    }

    @include md {
      height: 95px;
    }

    @include xs {
      height: 85px;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin: 35px 0 0;

    @include md {
      margin: 15px 0 0;
    }

    @include xs {
      margin: 0;
    }

    .swiper-pagination-bullet {
      display: inline-block;
      width: 15px;
      height: 15px;
      border: 1px solid #233148;
      border-radius: 50%;
      cursor: pointer;
      transition: background .3s;

      &:hover {
        background: rgba(35, 49, 72, 0.2);
      }

      &:not(:last-of-type) {
        margin: 0 11px 0 0;
      }
    }

    .swiper-pagination-bullet-active {
      background: #233148;
    }
  }
}
