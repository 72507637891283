.breadcrumbs {
  padding: 30px 0;

  @include md {
    padding: 25px 0 20px;
  }

  @include xs {
    padding: 18px 0 13px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $c-grey;
    transition: color .3s;

    @include xs {
      font-size: 12px;
    }

    &:not(:last-of-type) {
      display: inline-flex;
      align-items: center;

      &::after {
        content: '';
        width: 5px;
        height: 8px;
        flex-shrink: 0;
        margin: 0 13px;
        background: url('../img/page/breadcrumbs-arrow.svg') no-repeat center/cover;
      }
    }
  }

  &__list-link {
    color: $c-grey;

    &:hover {
      color: $c-green;
    }
  }
}
