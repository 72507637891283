.formats-nav {
  min-height: 74px;
  display: flex;
  align-items: center;
  background: $c-white;
  box-shadow: 74px 128px 59px rgba(0, 0, 0, 0.01), 42px 72px 50px rgba(0, 0, 0, 0.02), 18px 32px 37px rgba(0, 0, 0, 0.03), 5px 8px 20px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 10px 35px;

  @include md {
    min-height: 45px;
    padding: 10px 15px;
  }

  @media (max-width: 764px) {
    display: none;
  }

  &__item {
    position: relative;
    font-weight: 500;
    font-size: 22px;
    color: $c-grey;
    transition: color .3s;

    @include lg {
      font-size: 18px;
    }

    @include md {
      font-size: 15px;
    }

    &:not(:last-of-type) {
      margin: 0 80px 0 0;

      @media (max-width: 1250px) {
        margin: 0 32px 0 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -40px;
        display: inline-block;
        width: 1px;
        vertical-align: middle;
        height: 100%;
        background: rgba(0, 0, 0, 0.19);

        @media (max-width: 1250px) {
          right: -16px;
        }
      }
    }

    &:hover,
    &.active {
      color: $c-green;
    }
  }
}
