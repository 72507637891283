.container {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 40px;

  @include xs {
    padding: 0 25px;
  }
}
