.home-formats {
  padding: 0 0 60px;

  @include md {
    padding: 0 0 70px;
  }

  @include xs {
    padding: 0 0 45px;
  }

  &__title {
    max-width: 840px;
    width: 100%;
    margin: 0 auto 40px;

    @include md {
      margin: 0 0 30px;
    }

    @include xs {
      margin: 0 0 20px;
    }
  }

  &__tabs {
    margin: 0 0 40px;

    @include md {
      margin: 0 0 32px;
    }

    @include xs {
      margin: 0 0 18px;
    }
  }

  &__wrap {}

  &__item {
    display: none;

    &:nth-child(even) {
      flex-direction: row;

      .formats-item__info {
        margin: 0 30px 0 0;
      }
    }

    &:nth-child(4),
    &:last-of-type {
      .formats-item__img {
        @include md {
          width: 300px;
        }
      }
    }

    .formats-item__info {
      max-width: 570px;
    }

    &.active {
      display: flex;
      animation: faded .3s;
    }
  }
}
