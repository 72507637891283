.blogs-main {
  padding: 0 0 75px;

  @include md {
    padding: 0 0 45px;
  }

  &__title {
    margin: 0 0 20px;

    @include md {
      margin: 0 0 25px;
    }

    @include xs {
      margin: 0 0 10px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -44px;

    @include lg {
      justify-content: space-between;
      margin: 0 0 -30px;
    }
  }

  &__item {
    width: calc(33.33% - 41px * 2 / 3);
    min-height: 397px;
    background: $c-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px 91px 36px rgba(0, 0, 0, 0.01), 0px 51px 31px rgba(0, 0, 0, 0.02), 0px 23px 23px rgba(0, 0, 0, 0.03), 0px 6px 13px rgba(0, 0, 0, 0.04), 0px -5px 14px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    font-size: 16px;
    color: $c-grey;
    padding: 40px 30px;
    margin: 0 41px 44px 0;
    transition: box-shadow .3s;

    @include lg {
      width: calc(50% - 30px / 2);
      margin: 0 0 30px;
    }

    @include md {
      min-height: 323px;
      padding: 30px;
      font-size: 14px;
    }

    @include xs {
      width: 100%;
      padding: 30px 20px;
      margin: 0 0 20px;
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 97px 39px rgba(47, 185, 126, 0.01), 0px 54px 33px rgba(47, 185, 126, 0.02), 0px 24px 24px rgba(47, 185, 126, 0.03), 0px 6px 13px rgba(47, 185, 126, 0.04), 0px -7px 0px rgba(47, 185, 126, 0.04);
    }

    &:first-of-type {
      display: flex;
      align-items: center;
      width: calc(66.66% - 17px);

      @include lg {
        width: 100%;
        margin: 0 0 30px;
      }

      @include md {
        padding: 35px 30px;
      }

      @media (max-width: 650px) {
        display: block;
        padding: 30px;
      }

      @include xs {
        padding: 30px 20px;
        margin: 0 0 20px;
      }

      .blogs-main__item-img {
        max-width: 297px;
        width: 100%;
        height: auto;
        margin: 0 47px 0 0;

        @include lg {
          margin: 0 30px 0 0;
        }

        @media (max-width: 650px) {
          max-width: none;
          height: 154px;
          margin: 0 0 15px;
        }
      }

      .blogs-main__item-info {
        max-width: 280px;
        width: 100%;

        @include lg {
          max-width: none;
        }
      }
    }

    &:not(:first-of-type) {
      .blogs-main__item-info p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &:nth-child(3n+2) {
      margin-right: 0;
    }


  }

  &__item-img {
    height: 179px;
    flex-shrink: 0;
    margin: 0 0 20px;

    @include lg {
      height: 250px;
    }

    @include md {
      height: 155px;
      margin: 0 0 15px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__item-title {
    color: $c-blue;
    margin: 0 0 15px;

    @include md {
      margin: 0 0 10px;
    }

    @include xs {
      font-size: 18px;
    }
  }

  &__item-info {}
}
