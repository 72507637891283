.header {
  position: relative;
  z-index: 3;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(90.19deg, #283448 16.43%, #0E2E62 92.12%);
  }

  &--home {
    position: absolute;
    top: 7px;
    left: 0;
    width: 100%;

    &::after {
      content: none;
    }

    .header__info {
      @include md {
        background: transparent;
      }

      @media (max-width: 760px) {
        background: linear-gradient(106.65deg, #283448 12.29%, #0E2E62 93.43%);
      }
    }
  }

  &__wrap {
    display: flex;
    min-height: 90px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    @include md {
      min-height: 100px;
    }
  }

  &__logo {
    width: 160px;
    flex-shrink: 0;
    margin: 0 20px -5px 0;

    @include md {
      width: 145px;
    }

    @media (max-width: 760px) {
      position: relative;
      z-index: 6;
    }

    @include xs {
      width: 120px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    font-size: 16px;

    @include md {
      position: absolute;
      top: 100%;
      transform: translateY(-200px);
      left: 0;
      right: 0;
      z-index: -1;
      justify-content: flex-start;
      padding: 0 40px 20px;
      width: 100%;
      background: linear-gradient(106.65deg, #283448 12.29%, #0E2E62 93.43%);
      transition: transform .5s;

      &.active {
        transform: translateY(0);
      }

      @include md {
        font-size: 14px;
      }
    }

    @media (max-width: 760px) {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%) translateY(0);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      height: 100%;
      padding: 100px 30px 50px;
      z-index: 5;
      transition: 0.3s;
      overflow: auto;
      margin: 0;

      &.active {
        transform: translateX(0);
      }
    }
  }

  &__menu {
    margin: 0 auto;

    @include md {
      margin: 0 auto 0 0;
    }

    @media (max-width: 760px) {
      width: 100%;
      margin: 0 0 50px;
    }
  }

  &__menu-list {
    display: flex;

    @media (max-width: 760px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }

  &__nav-item {
    padding: 0 24px;

    @include lg {
      padding: 0 10px;
    }

    @include md {
      padding: 0 15px;
    }

    @media (max-width: 760px) {
      padding: 0;
      margin: 0 0 15px;
    }
  }

  &__nav-link {
    font-weight: 500;
    letter-spacing: 0.01em;
    color: $c-white;
    transition: color .3s;

    &.active {
      color: $c-green;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &__login {
    font-weight: 700;
    letter-spacing: 0.01em;
    color: $c-white;
    text-transform: uppercase;
    text-decoration: underline;
    margin: 0 20px 0 auto;
    transition: color .3s;

    &:hover {
      color: $c-green;
    }

    @media (max-width: 760px) {
      margin: 0 0 15px;
    }
  }

  &__btn {
    min-height: 45px;
    color: $c-white;
    font-size: inherit;
    min-width: 160px;
    flex-shrink: 0;

    @include lg {
      min-width: auto;
    }

    @include md {
      min-height: 40px;
    }

    @media (max-width: 760px) {
      margin: 0 0 50px;
    }
  }

  &__burger {
    display: none;

    @include md {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
      flex-shrink: 0;
      width: 30px;
      height: 25px;
      background: transparent;
      border-radius: 5px;
      position: relative;
      margin: 0 0 0 15px;

      span {
        background: $c-white;
        width: 22px;
        height: 3px;
        border-radius: 5px;
        pointer-events: none;
        transition: transform 0.3s;
      }

      &::before,
      &::after {
        content: '';
        background: $c-white;
        width: 100%;
        height: 3px;
        border-radius: 5px;
        transition: transform .3s;
      }

      &::after {
        width: 11px;
      }

      &.active {
        span {
          transform: scale(0);
        }

        &::before {
          position: absolute;
          top: 10px;
          transform: rotate(45deg);
        }

        &::after {
          width: 100%;
          position: absolute;
          bottom: 12px;
          transform: rotate(-45deg);
        }
      }
    }

    @media (max-width: 760px) {
      position: relative;
      z-index: 6;
    }
  }
}
