.home-other {
  padding: 0 0 100px;

  @include md {
    padding: 0 0 65px;
  }

  @include xs {
    padding: 0 0 40px;
  }

  &__title {
    margin: 0 0 100px;

    @include md {
      margin: 0 0 70px;
    }

    @include xs {
      margin: 0 0 42px;
    }
  }

  &__wrap {
    display: flex;
    margin: 0 -10px 40px;

    @include md {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -15px 35px;
    }

    @include xs {
      margin: 0 0 30px;
    }
  }

  &__item {
    display: inline-flex;
    flex-direction: column;
    flex: 0 1 33.33%;
    text-align: center;
    padding: 0 10px;

    @include md {
      flex: 0 1 50%;
      padding: 0 15px 70px;

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    @include xs {
      flex: 1 1 100%;
      padding: 0 0 40px;
    }
  }

  &__icon {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    background: linear-gradient(180deg, #263348 59.89%, #15B06E 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 50%;
    margin: 0 auto -47px;

    @include md {
      width: 73px;
      height: 73px;
      margin: 0 auto -37px;
    }

    @include xs {
      width: 60px;
      height: 60px;
      margin: 0 auto -30px;
    }

    img {
      width: 48px;
      height: 48px;

      @include md {
        width: 40px;
        height: 40px;
      }

      @include xs {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__item-info {
    flex-grow: 1;
    box-shadow: 0px 91px 36px rgba(0, 0, 0, 0.01), 0px 51px 31px rgba(0, 0, 0, 0.02), 0px 23px 23px rgba(0, 0, 0, 0.03), 0px 6px 13px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    background: $c-white;
    padding: 80px 20px 50px;

    @include md {
      padding: 50px 20px 35px;
    }

    @include xs {
      padding: 45px 20px 30px;
    }
  }

  &__item-title {
    margin: 0 0 18px;

    @include md {
      margin: 0 0 14px;
    }

    @include xs {
      margin: 0 0 10px;
    }
  }

  &__descr {
    font-size: 16px;

    @include md {
      font-size: 14px;
    }

    @include xs {
      font-size: 12px;
    }
  }

  &__btn {
    text-align: center;
  }
}
