.formats-item {
  display: flex;
  align-items: center;

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: 764px) {
      flex-direction: column-reverse;
    }

    .formats-item__info {
      margin: 0 0 0 30px;

      @media (max-width: 764px) {
        margin: 25px 0 0;
      }
    }
  }

  &:nth-child(4),
  &:last-of-type {
    .formats-item__img {
      @include md {
        width: 350px;
      }
    }
  }

  @media (max-width: 764px) {
    flex-direction: column-reverse;
  }

  &__info {
    max-width: 520px;
    width: 100%;
    margin: 0 30px 0 0;

    @media (max-width: 764px) {
      text-align: center;
      margin: 25px 0 0;
    }
  }

  &__title {
    text-transform: none;
    margin: 0 0 20px;

    @include md {
      margin: 0 0 15px;
    }

    @include xs {
      margin: 0 0 12px;
    }
  }

  &__info-descr {
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #263247;
      margin: 25px 0 0;

      li {
        display: inline-block;
        position: relative;
        padding: 0 0 0 35px;

        @include md {
          padding: 0 0 0 25px;
        }

        @media (max-width: 764px) {
          padding: 0;
        }

        &::before {
          content: '';
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background: #263247;
          position: absolute;
          top: 9px;
          left: 15px;

          @include md {
            top: 8px;
            left: 10px;
          }

          @media (max-width: 764px) {
            left: -10px;
          }

          @include xs {
            top: 7px;
          }
        }
      }

      @include md {
        margin: 23px 0 0;
        font-size: 14px;
      }

      @media (max-width: 764px) {
        max-width: 220px;
        width: 100%;
        margin: 20px auto 0;
        align-items: center;
      }

      @include xs {
        font-size: 12px;
      }
    }
  }

  &__btn {
    margin: 30px 0 0;

    @include xs {
      display: flex;
      font-size: 11px;
      padding: 0 15px;
    }
  }

  &__img {
    width: 405px;
    flex-shrink: 0;

    @include md {
      width: 230px;
    }
  }
}
