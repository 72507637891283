.blog-main {
  padding: 0 0 75px;

  @include md {
    padding: 0 0 45px;
  }

  &__img {
    max-width: 500px;
    width: 100%;
    margin: 0 0 20px;

    @include xs {
      margin: 0 0 10px;
    }
  }

  &__title {}

  &__head {
    margin: 0 0 30px;
    color: $c-blue-dark;

    @include xs {
      margin: 0 0 20px;
    }
  }
}
