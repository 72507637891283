.btn {
  min-width: 185px;
  min-height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $c-blue;
  text-transform: uppercase;
  border: 1px solid #2FB97E;
  padding: 3px 36px 0;
  border-radius: 30px;
  transition: background .3s, color .3s;

  @include md {
    font-size: 14px;
    min-height: 45px;
    padding: 1px 36px 0;
  }

  &:hover {
    color: $c-white;
    background: $c-green-btn;
  }
}
