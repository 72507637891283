.about-timeline {
  position: relative;
  padding: 160px 0 85px;

  @include md {
    padding: 130px 0 60px;
  }

  @include xs {
    padding: 80px 0 35px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% - 175px);
    min-height: 1085px;

    @include md {
      min-height: 983px;
      height: calc(100% - 160px);
    }

    @include xs {
      min-height: 885px;
      height: calc(100% - 75px);
    }
  }

  &__title {
    color: $c-white;
    margin: 0 0 60px;

    @include md {
      text-align: center;
      margin: 0 0 25px;
    }
  }

  &__graph {
    padding: 0 0 80px;

    @include md {
      padding: 0 0 55px;
    }

    @include xs {
      padding: 0 0 30px;

      img {
        width: 100%;
      }
    }
  }
}
