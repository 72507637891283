.about-more {
  padding: 0 0 80px;

  @include md {
    padding: 0 0 70px;
  }

  @include xs {
    padding: 0 0 40px;
  }

  &__title {
    margin: 0 0 40px;

    @include md {
      margin: 0 0 20px;
    }

    @include xs {
      margin: 0 0 10px;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -42px;

    @include md {
      margin: 0 0 -27px;
    }

    @media (max-width:650px) {
      justify-content: space-between;
      margin: 0 0 -18px;
    }
  }

  &__item {
    width: calc(33.33% - 22px * 2 / 3);
    background: $c-white;
    box-shadow: 0px 91px 36px rgba(23, 169, 108, 0.01), 0px 51px 31px rgba(23, 169, 108, 0.02), 0px 23px 23px rgba(23, 169, 108, 0.03), 0px 6px 13px rgba(23, 169, 108, 0.04), 0px 0px 0px rgba(23, 169, 108, 0.04);
    border-radius: 20px;
    padding: 45px 20px 55px;
    text-align: center;
    margin: 0 22px 42px 0;

    @include md {
      width: calc(33.33% - 32px * 2 / 3);
      padding: 35px;
      margin: 0 32px 27px 0;
    }

    @media (max-width:650px) {
      width: calc(50% - 14px / 2);
      padding: 25px 15px;
      margin: 0 0 18px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__icon {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    background: linear-gradient(180deg, #263348 59.89%, #15B06E 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 50%;
    margin: 0 auto 35px;

    @include md {
      margin: 0 auto 20px;
    }

    @include xs {
      width: 60px;
      height: 60px;
      margin: 0 auto 15px;
    }

    img {
      width: 48px;
      height: 48px;

      @include xs {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__descr {
    font-weight: 600;
    font-size: 22px;
    color: $c-blue;

    @include xs {
      font-size: 18px;
    }
  }
}
