.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  &--no-overhidden {
    overflow-x: inherit;
  }

  // используйте для страниц с min-height: 100vh для визуального скрытия скроллбара
  &--no-scrollbar {
    height: 100vh;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }
}

.scroll-lock {
  position: fixed;
  overflow-y: auto;
}
