.footer {
  padding: 50px 0 80px;
  background: linear-gradient(95.98deg, #263348 23.12%, #0E2E62 86.04%);

  &--about {
    background: transparent;
  }

  @include md {
    padding: 40px 0 60px;
  }

  @include xs {
    padding: 35px 0;
  }

  &__wrap {
    display: flex;

    @include md {
      display: block;
    }
  }

  &__col {
    max-width: 340px;
    width: 100%;
    margin: 0 85px 0 0;

    @include lg {
      margin: 0 30px 0 0;
    }

    @include md {
      max-width: none;
      margin: 0 0 30px;
    }

    @include xs {
      margin: 0 0 25px;
    }
  }

  &__logo {
    display: block;
    width: 190px;
    margin: 0 0 20px;

    @include md {
      width: 140px;
      margin: 0 0 15px;
    }

    @include xs {
      width: 120px;
    }
  }

  &__address {
    color: $c-white;
    font-weight: 300;
    font-size: 16px;

    @include md {
      font-size: 14px;
    }

    @include xs {
      font-size: 12px;
    }
  }

  &__links {
    margin: 0 20px 0 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include md {
      text-align: left;
      align-items: flex-start;
      margin: 0 0 20px;
    }

    @include xs {
      margin: 0 0 15px;
    }
  }

  &__links-item {
    font-size: 16px;
    color: $c-white;
    margin: 0 0 20px;
    transition: color .3s;

    @include md {
      font-size: 14px;
      margin: 0 0 15px;
    }

    @include xs {
      margin: 0 0 10px;
    }

    &:hover {
      color: $c-green;
    }
  }

  &__contacts {
    margin: 0 0 0 auto;
  }

  &__contacts-title {
    font-weight: 500;
    font-size: 16px;
    color: $c-white;
    text-transform: uppercase;
    margin: 0 0 10px;
    text-align: right;

    @include md {
      text-align: left;
    }

    @include xs {
      font-size: 14px;
      margin: 0 0 6px;
    }
  }

  &__contacts-wrap {
    display: flex;
    align-items: center;
  }

  &__contacts-item {
    &:hover {
      .footer__contacts-svg {
        fill: $c-green;
      }
    }

    &:not(:last-of-type) {
      margin: 0 10px 0 0;

      @include xs {
        margin: 0 7px 0 0;
      }
    }
  }

  &__contacts-svg {
    fill: $c-white;
    transition: fill .3s;

    @include xs {
      width: 80%;
      height: auto;
    }
  }
}
