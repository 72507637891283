.formats-main {
  padding: 0 0 70px;

  @include md {
    padding: 0 0 60px;
  }

  @include xs {
    padding: 0 0 50px;
  }

  &__title {
    margin: 0 0 30px;

    br {
      display: none;
    }

    @include md {
      margin: 0 0 20px;

      br {
        display: inline;
      }
    }

  }

  &__nav {
    margin: 0 0 60px;

    @include md {
      margin: 0 0 30px;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    @media (max-width: 764px) {
      &:not(.active) {
        display: none;
      }
    }

    &:not(:last-of-type) {
      margin: 0 0 90px;

      @include md {
        margin: 0 0 60px;
      }

      @media (max-width: 764px) {
        margin: 0;
      }
    }
  }
}
