.formats-select {
  display: none;

  width: 100%;

  @media (max-width: 764px) {
    display: block;
    margin: 0 0 18px;
  }



  .ss-single-selected {
    height: 35px;
    font-weight: 600;
    font-size: 14px;
    color: $c-green;
    border: 1px solid $c-green;
    padding: 0 20px;
    background: $c-white;
    box-shadow: 74px 128px 59px rgba(0, 0, 0, 0.01), 42px 72px 50px rgba(0, 0, 0, 0.02), 18px 32px 37px rgba(0, 0, 0, 0.03), 5px 8px 20px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
  }

  .ss-main .ss-single-selected .ss-arrow,
  .ss-main .ss-single-selected .ss-deselect {
    margin: 0;
  }

  .ss-main .ss-single-selected .ss-arrow span.arrow-down {
    width: 16px;
    height: 9px;
    border: none;
    padding: 0;
    background: url(../img/page/select-arrow.svg) no-repeat center/cover;
    transform: rotate(0deg);
    margin: 0;
    transition: transform .3s, background .3s;
  }

  .ss-main .ss-single-selected .ss-arrow span.arrow-up {
    width: 16px;
    height: 9px;
    border: none;
    padding: 0;
    background: url(../img/page/select-arrow.svg) no-repeat center/cover;
    transform: rotate(-180deg);
  }

  .ss-content,
  .ss-content .ss-search input,
  .ss-main .ss-multi-selected {
    background: $c-white;
    border: 1px solid $c-green;
    box-shadow: 74px 128px 59px rgba(0, 0, 0, 0.01), 42px 72px 50px rgba(0, 0, 0, 0.02), 18px 32px 37px rgba(0, 0, 0, 0.03), 5px 8px 20px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    margin: 9px 0 0;
  }

  .ss-content .ss-list .ss-option {
    font-size: 14px;
    color: $c-grey;
    padding: 11px 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #EFEFEF;
    }

    &:hover {
      background: rgba(21, 176, 110, 0.04);
    }
  }
}
