.title {
  font-weight: 700;
  font-size: 45px;
  color: $c-blue;
  text-transform: uppercase;

  @include md {
    font-size: 40px;
  }

  @include xs {
    font-size: 20px;
  }

  &--dark {
    color: $c-blue-dark;
  }

  &--middle {
    font-weight: 600;
    font-size: 35px;

    @include md {
      font-size: 30px;
    }

    @include xs {
      font-size: 20px;
    }
  }

  &--small {
    text-transform: none;
    font-weight: 600;
    font-size: 22px;

    @include md {
      font-size: 18px;
    }

    @include xs {
      font-size: 16px;
    }
  }

  &--textup-none {
    text-transform: none;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}
