.home-hero {
  position: relative;
  z-index: 1;
  min-height: 820px;
  padding: 120px 0;

  @include md {
    min-height: 668px;
    padding: 148px 0 0;
  }

  @include xs {
    min-height: auto;
    padding: 160px 0 150px;
  }

  &__dots {
    position: absolute;
    top: 128px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;

    @include xs {
      top: 100px;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &__wrap {
    display: flex;
  }

  &__info {
    max-width: 616px;
    width: 100%;
    color: $c-white;
    margin: 35px 30px 0 0;

    @include md {
      margin: 0;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 60px;
    margin: 0 0 20px;

    @include md {
      font-size: 50px;
    }

    @include xs {
      font-size: 34px;
      margin: 0 -15px 20px 0;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 30px;
    margin: 0 0 40px;

    @include md {
      max-width: 358px;
      width: 100%;
      font-size: 27px;
      margin: 0 0 33px;
    }

    @include xs {
      font-size: 22px;
    }
  }

  &__btn {
    color: $c-white;
  }

  &__img {
    width: 469px;

    @include md {
      width: 340px;
      flex-shrink: 0;
      margin: 75px 0 0 -80px;
    }

    @media (max-width: 750px) {
      display: none;
    }
  }
}
