.contacts-main {
  padding: 0 0 70px;

  @include md {
    padding: 0 0 65px;
  }

  @include xs {
    padding: 0 0 50px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;

    @include lg {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__info {
    max-width: 426px;
    width: 100%;
    font-weight: 500;
    margin: 0 40px 0 0;

    @include lg {
      max-width: none;
      margin: 0 0 30px;
    }
  }

  &__title {
    margin: 0 0 60px;

    @include md {
      margin: 0 0 40px;
    }

    @include xs {
      margin: 0 0 25px;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px;

    @include xs {
      margin: 0 0 10px;
    }
  }

  &__links-item {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    color: $c-grey;
    margin: 0 0 20px;
    transition: color .3s;

    @include xs {
      font-size: 18px;
    }

    &:hover {
      color: $c-green;
    }
  }

  &__links-item-icon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin: 0 20px 0 0;

    @include xs {
      margin: 0 14px 0 0;
    }
  }

  &__address {
    font-size: 20px;
    color: $c-blue;

    @include xs {
      font-size: 14px;
    }
  }

  &__form {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 566px;
    width: 100%;
    background: $c-white;
    box-shadow: 0px 91px 36px rgba(0, 0, 0, 0.01), 0px 51px 31px rgba(0, 0, 0, 0.02), 0px 23px 23px rgba(0, 0, 0, 0.03), 0px 6px 13px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: 60px;

    @include lg {
      max-width: 700px;
      padding: 60px 90px;
      align-self: center;
    }

    @media (max-width: 700px) {
      padding: 40px 20px 45px;
    }
  }

  &__form-label {
    position: relative;
    display: block;
    margin: 0 0 15px;

    &.check {
      .contacts-main__form-input {
        border-color: $c-green;
      }

      .contacts-main__form-input-icon--check {
        display: block;
      }

    }

    &.error {
      .contacts-main__form-input {
        border-color: #DB1515;
      }

      .contacts-main__form-input-icon--error {
        display: block;
      }

      .contacts-main__form-error {
        display: block;
      }
    }
  }

  &__form-input {
    width: 100%;
    height: 45px;
    font-size: 14px;
    color: $c-blue;
    letter-spacing: 0.01em;
    border: 1px solid rgba(45, 68, 89, 0.24);
    border-radius: 30px;
    padding: 0 50px 0 25px;

    @include xs {
      padding: 0 50px 0 15px;
    }

    &::placeholder {
      color: rgba(40, 52, 72, 0.53);
    }
  }

  &__form-input-icon {
    width: 24px;
    height: 24px;
    top: 11px;
    right: 20px;
    position: absolute;
    display: none;
  }

  &__form-error {
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.01em;
    margin: 6px 0 0;
    color: rgba(219, 21, 21, 0.53);
    padding: 0 0 0 25px;
    display: none;
  }

  &__form-textarea {
    height: 107px;
    width: 100%;
    font-size: 14px;
    color: $c-blue;
    letter-spacing: 0.01em;
    padding: 17px 26px;
    border: 1px solid rgba(45, 68, 89, 0.24);
    border-radius: 16px;
    margin: 0 0 32px;

    @include xs {
      padding: 17px;
      margin: 0 0 20px;
    }

    &::placeholder {
      color: rgba(40, 52, 72, 0.53);
    }
  }

  &__form-btn {
    align-self: center;
  }

  &__form-dots {
    position: absolute;
    top: 28px;
    left: 48px;
    z-index: -1;

    @include md {
      top: 67px;
      left: 50px;
    }

    @media (max-width: 700px) {
      display: none;
    }
  }
}
