.home-dots {
  position: absolute;
  top: 777px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  @include md {
    top: 750px;
  }

  @include xs {
    top: 800px;
  }
}

.about-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 933px;

  @include md {
    height: 999px;
  }

  @media (max-width: 680px) {
    height: 1324px;
  }

  @include xs {
    object-fit: cover;
    object-position: right;
  }
}

.about-dots {
  position: absolute;
  top: 174px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  @include md {
    top: 148px;
  }

  @include xs {
    top: 410px;
  }
}

.blogs-dots {
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  @include md {
    top: 145px;
    left: 70%;
  }

  @include xs {
    top: 112px;
  }
}

.contacts-dots {
  position: absolute;
  top: 350px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  @include md {
    top: 184px;
    left: 70%;
  }

  @include xs {
    top: 132px;
  }
}

.blog-dots {
  position: absolute;
  top: 162px;
  left: 70%;
  transform: translateX(-50%);
  z-index: -1;

  @include md {
    top: 184px;
    left: 70%;
  }

  @include xs {
    top: 759px;
  }
}

.formats-dots {
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  @include md {
    top: 175px;
  }

  @include xs {
    top: 214px;
  }
}

@keyframes faded {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}
