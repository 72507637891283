@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat/montserrat-v25-latin-300.woff2") format("woff2"),
    url("../fonts/Montserrat/montserrat-v25-latin-300.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat/montserrat-v25-latin-regular.woff2") format("woff2"),
    url("../fonts/Montserrat/montserrat-v25-latin-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat/montserrat-v25-latin-500.woff2") format("woff2"),
    url("../fonts/Montserrat/montserrat-v25-latin-500.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat/montserrat-v25-latin-600.woff2") format("woff2"),
    url("../fonts/Montserrat/montserrat-v25-latin-600.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat/montserrat-v25-latin-700.woff2") format("woff2"),
    url("../fonts/Montserrat/montserrat-v25-latin-700.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "MontserratAlternates";

  font-display: swap;
  src:
    url("../fonts/Montserrat/MontserratAlternates-Bold.woff2") format("woff2"),
    url("../fonts/Montserrat/MontserratAlternates-Bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Inter";

  font-display: swap;
  src:
    url("../fonts/Inter/inter-v12-latin-regular.woff2") format("woff2"),
    url("../fonts/Inter/inter-v12-latin-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Inter";

  font-display: swap;
  src:
    url("../fonts/Inter/inter-v12-latin-700.woff2") format("woff2"),
    url("../fonts/Inter/inter-v12-latin-700.woff") format("woff");
}
