.table {
  display: flex;
  align-items: flex-start;
  font-family: $font-family-inter;

  @media (max-width: 720px) {
    display: block;
  }

  &__col {
    flex: 0 1 20%;
    color: $c-blue;
    border-top: 1px solid #828282;
    border-right: 1px solid #828282;
    overflow: hidden;

    &:first-of-type {
      border-left: 1px solid #828282;
      border-radius: 4px 0 0 4px;

      @media (max-width: 720px) {
        border-radius: 4px;
      }
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;

      @media (max-width: 720px) {
        border-radius: 4px;
      }
    }

    @media (max-width: 720px) {
      border-radius: 4px;
      border: 1px solid #828282;
      border-bottom: none;

      &:not(:last-of-type) {
        margin: 0 0 23px;
      }
    }
  }

  &__col-title {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 10px 13px;
    background: #F4F4F4;
    border-bottom: 1px solid #828282;

    @include md {
      padding: 10px 8px;
    }

    @media (max-width: 720px) {
      padding: 10px 16px;
    }
  }

  &__col-title-text {
    font-weight: 700;
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include md {
      font-size: 16px;
    }
  }

  &__col-cell {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px 13px;
    border-bottom: 1px solid #828282;

    @include md {
      padding: 10px 4px 10px 8px;
    }

    @media (max-width: 720px) {
      padding: 10px 16px;
    }

    p {
      font-size: 16px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include md {
        font-size: 14px;
      }
    }
  }
}
