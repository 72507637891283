.about-advantages {
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
  padding: 0 0 80px;

  @include md {
    padding: 0 0 60px;
  }

  @include xs {
    padding: 0 0 40px;
  }

  &__title {
    color: $c-white;
    margin: 0 0 50px;

    @include md {
      margin: 0 0 30px;
    }

    @include xs {
      margin: 0 0 25px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 -20px;

    @media (max-width: 680px) {
      display: block;
      margin: 0 0 -35px;
    }
  }

  &__item {
    flex: 0 1 33.33%;
    color: $c-white;
    text-align: center;
    padding: 0 20px;

    @media (max-width: 680px) {
      padding: 0 0 35px;
    }

    @include md {
      font-size: 14px;
    }
  }

  &__icon {
    width: 55px;
    height: 55px;
    margin: 0 auto 25px;

    @include md {
      margin: 0 auto 10px;
    }
  }

  &__item-title {
    color: $c-white;
    margin: 0 0 20px;

    @include md {
      margin: 0 0 13px;
    }

    @include xs {
      font-size: 18px;
    }
  }
}
