.numbers {
  .container {
    max-width: 1370px;
  }

  &__wrap {
    display: flex;
    margin: 0 -10px;

    @include md {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -10px -50px;
    }

    @media (max-width: 750px) {
      margin: 0 0 -30px;
    }
  }

  &__item {
    flex: 0 1 20%;
    text-align: center;
    padding: 0 10px;

    @include md {
      flex: 0 1 33.33%;
      padding: 0 10px 50px;
    }

    @media (max-width: 750px) {
      flex: 1 1 100%;
      padding: 0 0 30px;
    }
  }

  &__descr {
    font-weight: 500;
    font-size: 21px;

    @include lg {
      font-size: 16px;
    }

    @include md {
      font-size: 18px;
    }

    @include xs {
      font-size: 16px;
    }
  }
}
