.about-numbers {

  &__content {
    color: $c-blue-dark;
    background: $c-white;
    box-shadow: 0px 91px 36px rgba(0, 0, 0, 0.01), 0px 51px 31px rgba(0, 0, 0, 0.02), 0px 23px 23px rgba(0, 0, 0, 0.03), 0px 6px 13px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: 75px 50px 90px;

    @include lg {
      padding: 75px 20px 90px;
    }

    @include md {
      padding: 50px 48px 80px;
    }

    @include xs {
      padding: 35px 20px;
    }

  }

  &__title {
    margin: 0 0 50px;

    @include md {
      margin: 0 0 25px;
    }

    @include xs {
      margin: 0 0 20px;
    }
  }

  &__val {
    background: -webkit-linear-gradient(180deg, #283448 0%, #0E2E62 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 65px;

    @include md {
      background: transparent;
      -webkit-background-clip: inherit;
      -webkit-text-fill-color: $c-blue;
      font-size: 70px;
      color: $c-blue;
    }

    @include xs {
      font-size: 30px;
    }
  }
}
