.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include sm {
      padding: 60px 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    max-width: 566px;
    width: 100%;

    @include md {
      width: 100%;
    }
  }

  &__close-btn {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 46px;
    height: 46px;
    z-index: 1;

    @include xs {
      top: 5px;
      right: 5px;
    }

    &::before,
    &::after {
      content: '';
      background: $c-blue;
      width: 23px;
      height: 2px;
      left: 28%;
      transition: .3s;
    }

    &::before {
      position: absolute;
      top: 22px;
      transform: rotate(45deg);
    }

    &::after {
      position: absolute;
      bottom: 22px;
      transform: rotate(-45deg);
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include sm {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}
