.home-more {
  padding: 44px 0 80px;

  @include md {
    padding: 35px 0 65px;
  }

  @include xs {
    padding: 35px 0 40px;
  }

  &__title {
    margin: 0 0 60px;

    @include md {
      margin: 0 0 45px;
    }

    @include xs {
      margin: 0 0 30px;
    }
  }

  &__wrap {
    display: flex;
    margin: 0 -10px;

    @include md {
      flex-wrap: wrap;
      margin: 0 -15px -40px;
    }

    @include xs {
      margin: 0 0 -30px;
    }
  }

  &__item {
    text-align: center;
    flex: 0 1 25%;
    padding: 0 10px;

    @include md {
      flex: 0 1 50%;
      padding: 0 15px 40px;
    }

    @include xs {
      flex: 1 1 100%;
      padding: 0 0 30px;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 60px;
    margin: 0 auto 25px;

    @include md {
      width: auto;
      height: 48px;
      margin: 0 auto 18px;

      img {
        width: 70%;
      }
    }

    @include xs {
      margin: 0 auto 15px;
    }
  }

  &__item-title {
    margin: 0 0 15px;

    @include md {
      margin: 0 0 12px;
    }

    @include xs {
      margin: 0 0 10px;
    }
  }

  &__descr {
    font-size: 16px;

    @include md {
      font-size: 14px;
      letter-spacing: -0.01em;
    }
  }
}
