.about-main {
  padding: 0 0 44px;

  @include md {
    padding: 0 0 28px;
  }

  @include xs {
    padding: 0 0 42px;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
  }

  &__info {
    max-width: 467px;
    width: 100%;

    @include md {
      max-width: none;
    }
  }

  &__title {
    margin: 0 0 20px;

    @include md {
      margin: 0 0 15px;
    }

    @include xs {
      margin: 0 0 12px;
    }
  }

  &__info-text {
    margin: 0 0 40px;

    @include md {
      margin: 0 0 25px;
    }

    p {
      margin: 0 0 20px;
      letter-spacing: -0.01em;

      @include xs {
        margin: 0 0 14px;
      }

      b {
        font-weight: 500;
        color: $c-blue;
      }
    }
  }

  &__img {
    max-width: 600px;
    width: 100%;
    margin: 0 0 0 100px;

    @include md {
      display: none;
    }
  }
}
